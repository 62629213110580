import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('./pages/Home'));
const Layout = React.lazy(() => import('./components/Layout'));
const Functional = React.lazy(() => import('./pages/Functional'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Platform = React.lazy(() => import('./pages/Platform'));
const Price = React.lazy(() => import('./pages/Price'));
const NoMatch = React.lazy(() => import('./pages/NoMatch'));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <React.Suspense >
              <Layout />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense >
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path='contact'
            element={
              <React.Suspense >
                <Contact />
              </React.Suspense>
            }
          />
          <Route
            path='functional'
            element={
              <React.Suspense>
                <Functional />
              </React.Suspense>
            }
          />
          <Route
            path='platform'
            element={
              <React.Suspense >
                <Platform />
              </React.Suspense>
            }
          />
          <Route
            path='price'
            element={
              <React.Suspense >
                <Price />
              </React.Suspense>
            }
          />
          <Route
            path='*'
            element={
              <React.Suspense >
                <NoMatch />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
